import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/en'
import {
  Banner,
  SummaryTitle,
  TabSwitcher,
  Button,
  ImagePreview,
  PageBottomCard,
  TestimonyCard,
} from 'components/index'
import Seo from 'components/EN/Seo'
import { ButtonGroup } from 'components/Base/Button'
import GreenCard from 'components/Analysis/GreenCard'
import ShowCard from 'components/engines/ShowCard/index'
import SigningPlate from 'components/Solutions/SigningPlate'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import * as styles from './index.module.less'
import JoeEnginesPreview from './img/JoeEnginesPreviewEn.png'
import Notebook from './img/notebook_icon_en.png'

const Joe = (props) => {
  const { data } = props
  const {
    allJoeYaml: { nodes },
  } = data
  const { banner, part2, part3, part4, part5, part6 } = nodes[0] || {}

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="Sensors Customer Journey Optimization | SensorsData"
        description="Sensors Customer Journey Optimization is a is highly open platform that provides open interface capabilities for both enterprises and partners. You can orchestrate and optimize customer journeys to engage with customers, achieving interactive experience management throughout the user's lifecycle"
        keywords="User Journey, Customer Journey Orchestration, User Experience, Customer Journey Map, Customer Journey Analysis, Optimization"
      />

      <div className={styles.JOERoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={banner?.title}
            desc={banner?.desc}
            buttons={banner?.buttons}
            enTitle={banner?.enTitle}
            className={classnames(styles.banner)}
            h1tag={true}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
            enTitleClassName={styles.enTitle}
          />
        </div>

        <div className={styles.wWarrper}>
          <SummaryTitle
            title="How to Orchestrate Customer Journeys across the Lifecycle?"
            desc="We can orchestrate customer journey through a four-step workflow for comprehensive lifecycle interaction experience management."
          />
          <div className="flex justify-center md:mt-[73px] pt-[2.6rem] lg:pt-0 mt-[2.6rem] md:px-0 px-[2rem]">
            <ShowCard stepArr={part2.stepArr} buttons={part2.buttons} />
          </div>
        </div>

        <div className={classnames(styles.bWarrper)} style={isMb ? undefined : { paddingBottom: '70px' }}>
          <SummaryTitle
            title="Enable Personalized Customer Journeys with Four Service Capabilities"
            desc="Sensors Customer Journey Optimization empowers both enterprises and partners through an API-first approach."
          />
          <div className={styles.partContent}>
            <TabSwitcher
              data={part3.data}
              longTitle
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
            />
          </div>
        </div>
        <div className={classnames(styles.wWarrper, styles.notebook)}>
          <div className={classnames('lg:w-[1200px] mx-auto lg:flex ')}>
            <div>
              <img
                className="lg:w-[418px] lg:h-[228px]"
                src={Notebook}
                alt="Sensors CDP + Sensors Customer Journey Optimization"
              />
            </div>
            <div className="lg:ml-[70px] lg:mt-[40px] flex-1 ">
              <h4 className="mt-[26px] lg:mt-0 text-[16px] w-[172px] mx-auto lg:w-auto lg:text-[18px] font-medium text-[31f2d3d] leading-[22px] lg:leading-[25px]">
                {isMb
                  ? 'Sensors CDP + Sensors Customer Journey Optimization'
                  : 'Sensors CDP + Sensors Customer Journey Optimization'}
              </h4>
              <p className="px-[16px] lg:px-0 text-center lg:text-left mt-[10px] lg:mt-[6px] text-[14px] text-[#475669] leading-[20px]">
                Pair Sensors CDP with Sensors Customer Journey Optimization to orchestrate real-time, personalized
                customer journeys and activating data value.
              </p>
              <p className="mt-[30px] text-center lg:text-left lg:mt-[60px]">
                <Button href="https://www.sensorsdata.com/engines/CDP" target="_blank" btnType="primary">
                  Learn More
                </Button>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...part6} />
          </div>
          <div className={classnames(' lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full', styles.carouselWrapper)}>
            <ScrollLogoWall
              className={classnames(' mt-[20px] ', styles.logo_wall_en)}
              maskVisible={!isMb}
              title={part6.title}
              logo_wall={part6.icons}
            />
          </div>
        </div>

        <div className={styles.bWarrper}>
          <SummaryTitle
            title="Full View of Sensors Customer Journey Optimization"
            desc="We provide four service capabilities and a series of marketing applications to empower customer journey orchestration across the customer lifecycle."
          />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview
                imageUrl={JoeEnginesPreview}
                alt="Customer Journey Optimization Engine Application Scenario"
                language="en"
              />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center')}>
            <Button href="/api/client/user/router?demo=Retail" target="_blank" btnType="primary">
              Free Demo
            </Button>
            <Button href="/form/parade/" target="_blank" btnType="primary" ghost>
              Contact us
            </Button>
          </ButtonGroup>
        </div>

        <div className={styles.wWarrper}>
          <SummaryTitle
            title="Why Choose Sensors Data?"
            desc="Sensors Customer Journey Optimization is real-time, flexible, high-concurrency, and open, making it a great choice for businesses of all sizes."
          />
          <div className="lg:mt-[60px] mt-[26px] lg:w-[1200px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part4?.dataSource?.map((item) => {
              return <GreenCard className={`mt-[14px] lg:mt-0 ${styles.gCard}`} key={item?.title} dataSource={item} />
            })}
          </div>
          <ButtonGroup className="lg:mt-[60px] mt-[30px] text-center">
            <Button href="/api/client/user/router?demo=Retail" target="_blank" btnType="primary">
              Free Demo
            </Button>
            <Button href="/form/parade/" target="_blank" btnType="primary" ghost>
              Contact us
            </Button>
          </ButtonGroup>
        </div>

        <div className={styles.bWarrper} style={{ paddingBottom: '0px' }}>
          <SummaryTitle title="Discover Other Products" />
          <SigningPlate className={`lg:block`} dataSource={part5} linkIconClassName={styles.linkIconClassName} />
          <div className="lg:mt-[40px] mt-[2.6rem]">
            <PageBottomCard
              title="Ready to Get Started？"
              desc="See how Sensors Data can help you make better customer journey"
              leftButtonText="Contact Sales"
              leftButtonHref="/form/parade/"
              descClassName={styles.pageBottomCardDesc}
              type="newBg"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allJoeYaml(filter: { banner: { title: { eq: "Customer Journey Optimization" } } }) {
      nodes {
        banner {
          title
          enTitle
          desc
          buttons {
            text
            href
            btnType
            ghost
          }
        }
        part2 {
          stepArr {
            title
            desc
            img {
              publicURL
            }
            mbImg {
              publicURL
            }
          }
          buttons {
            text
            href
            ghost
          }
        }
        part3 {
          data {
            title
            reverse
            showIcon
            sortTitle
            content {
              img {
                publicURL
              }
              alt
              introduction1
              buttons {
                btnType
                href
                text
                target
              }
              introductionList {
                content
              }
            }
          }
        }
        part4 {
          dataSource {
            title
            desc
            content
          }
        }
        part5 {
          left {
            title
            bg {
              publicURL
            }
            desc
            btn_text
            btn_link
          }
          right {
            title
            bg {
              publicURL
            }
            desc
            btn_text
            btn_link
          }
        }
        part6 {
          title
          text
          signature
          imgUrl {
            publicURL
          }
          icons {
            publicURL
          }
        }
      }
    }
  }
`

export default Joe
